import React, { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';

import { useConsentManagement } from './hooks/use-consent-management';
import { usePrivacyModalSetup } from './hooks/use-privacy-modal-setup';
import { FidesStyles } from './fides.styles';
import { getFides } from './helpers';
import type { FidesSDK } from './types';

const isProd = process.env.APP_ENV === 'production';
const privacyCenterUrl = isProd ? 'https://privacy.dutchie.com' : 'https://privacy.dutchie.dev';
const FidesJSUrl = `${privacyCenterUrl}/fides.js`;

export function FidesController(): FidesSDK {
  const [isFidesLoaded, setIsFidesLoaded] = useState(false);
  const [isModalReadyForSetup, setIsModalReadyForSetup] = useState(false);
  const { isConsentManagementEnabled } = useConsentManagement();
  usePrivacyModalSetup(isModalReadyForSetup);

  const onFidesLoaded = useCallback(() => {
    if (!isFidesLoaded) {
      setIsFidesLoaded(true);
    }
  }, [isFidesLoaded]);

  const onFidesInitialized = useCallback(() => {
    const fides = getFides();
    const hasModalExperience = fides?.experience?.experience_config?.component === 'modal';
    if (!isModalReadyForSetup && hasModalExperience) {
      setIsModalReadyForSetup(true);
    }
  }, [isModalReadyForSetup]);

  useEffect((): (() => void) | void => {
    if (isFidesLoaded) {
      window.addEventListener('FidesInitialized', onFidesInitialized);

      return () => {
        window.removeEventListener('FidesInitialized', onFidesInitialized);
      };
    }

    return undefined;
  }, [isFidesLoaded, onFidesInitialized]);

  if (!isConsentManagementEnabled) {
    return null;
  }

  return (
    <>
      <Script key='fides-sdk' src={FidesJSUrl} onLoad={onFidesLoaded} />
      <style data-testid='fides-css'>{FidesStyles}</style>
    </>
  );
}
